.error {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.error__text,
.error__image {
  width: 47%;
}

.error__text h1 {
  font-size: 180px !important;
  margin-bottom: 0;
}

.error__text h2 {
  font-size: 50px !important;
  color: var(--martinique-color);
  margin-bottom: 0;
}

.error__text p {
  font-size: 18px;
}

.error__image > img {
  width: 90%;
  display: block;
  -webkit-margin-start: auto;
  margin-inline-start: auto;
}

/*********************
START: MEDIA
*********************/
@media screen and (max-width: 1200px) {
  .error__text h1 {
    font-size: 150px !important;
  }
}

@media screen and (max-width: 992px) {
  .error__text h1 {
    font-size: 100px !important;
  }
}

@media screen and (max-width: 768px) {
  .error__text h1 {
    font-size: 70px !important;
  }
}

@media screen and (max-width: 576px) {
  .error {
    flex-direction: column-reverse;
    justify-content: center;
    padding: 3rem 0;
  }

  .error__image {
    width: 60%;
    margin-bottom: 2rem;
  }
  .error__text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .error__text h1 {
    font-size: 50px !important;
  }

  .error__text p {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 425px) {
  .error__text h1 {
    font-size: 40px !important;
  }
}
/*********************
  END: MEDIA
*********************/
