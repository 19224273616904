/*********************
START: PUBLIC
*********************/
@import url(typography.module.css);
@import url(icons.css);

*,
*::after,
*::before {
   margin: 0;
   padding: 0;
   box-sizing: border-box !important;
}

:root {
   --very-light-blue: rgb(237, 235, 247);
   --primary-color: #544da0;
   --primary-color-with-opacity: #4b4680;
   --yellow-color: #ffc048;
   --primary-color-grey: #545459;
   --secondary-color-grey: #555555;
   --third-color-grey: #9e9e9e;
   --martinique-color: #2d2b47;
   --grey-color: #f5f5f5;
   --gray-color-dark: #7a7a7a;
   --light-blue: #dcdaf2;
   --light-purple: #7a5bea;
   --success-color: #4bae4f;
   --white-color: #ffffff;
   --navyBlue-color: #001529;
   --black-color: #000000;
   --alert-light-success: #e4f0e4;
   --alert-info: #2196f3;
   --alert-info-light: #d0e5f5;
   --alert-error: #e24c4b;
   --alert-error-light: #fce1e1;
   --alert-warning-light: #faf0dc;
   --silver-color: #9d9d9d;
   --silver-color-light: #e6e6e8;

   /* border */
   --borderRadius5: 5px;
   --borderRadius10: 10px;
   --borderRadius25: 25px;
   --borderRadius50: 50px;

   /* shadow */
   --boxShadowBlack: 0px 0px 20px rgba(0, 0, 0, 0.2);
   --boxShadowPurple: 0px 0px 20px rgba(84, 77, 160, 0.25);
   --spaceTop: 3.2rem;
}

ul {
   list-style: none;
}

html {
   scroll-behavior: smooth;
   font-size: 16px;
}

body {
   font-family: 'Ubuntu Regular' !important;
   box-sizing: border-box;
   letter-spacing: normal;
   direction: ltr;
   overflow-x: hidden !important;
   width: 100%;
   height: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-size: calc(12px + (26 - 12) * ((100vw - 300px) / (2560 - 300))) !important;
   background: var(--grey-color) !important;
   min-height: 100vh;
   position: relative;
}

#root {
   height: 100%;
}

/*********************
  END: PUBLIC
*********************/

/*********************
START: PUBLIC ANT DESIGN
*********************/
.ant-btn-loading {
   background-color: var(--primary-color) !important;
}

.ant-input:-webkit-autofill,
.ant-input:-webkit-autofill:hover,
.ant-input:-webkit-autofill:focus {
   background-color: transparent;
   -webkit-text-fill-color: var(--primary-color);
   transition: background-color 5000s ease-in-out 0s;
}

.ant-typography {
   color: var(--black-color);
}

.ant-layout {
   position: relative;
   background: var(--grey-color) !important;
   overflow-x: hidden !important;
   /* min-height: calc(100vh - 70px); */
}

.ant-form-item-extra {
   min-height: 15px;
   font-size: 12px;
   display: flex;
   align-items: center;
   margin-top: 0.3rem;
}

.ant-form-item-extra img {
   margin-right: 5px;
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
   padding: 0 0 4px;
   line-height: 1;
}

.ant-input {
   line-height: 1.555;
   height: 40px;
   padding: 0 0.4rem !important;
   border-radius: var(--borderRadius5);
   border: 1px solid var(--silver-color) !important;
   color: var(--primary-color) !important;
   font-size: 1rem;
}

.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus,
.ant-input-group .ant-input:hover,
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
   border-color: var(--primary-color) !important;
   box-shadow: none !important;
   color: var(--primary-color) !important;
}

.ant-input-search-button {
   height: 40px !important;
   border: 1px solid var(--silver-color);
   border-top-right-radius: var(--borderRadius5) !important;
   border-bottom-right-radius: var(--borderRadius5) !important;
}

.ant-input-search-button:hover,
.ant-input-search-button:focus {
   border: 1px solid var(--primary-color) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
   border: 1px solid var(--light-purple);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
   box-shadow: var(--boxShadowPurple);
}

.ant-input-affix-wrapper {
   line-height: 1.555;
   border-radius: var(--borderRadius5);
   border: 1px solid var(--light-purple);
}

.ant-card-body {
   padding: 16px 24px;
}

.ant-input-search-button {
   height: 31px;
}

.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-number:hover {
   border-color: var(--primary-color);
   box-shadow: unset;
}

.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
   color: var(--primary-color);
}

.ant-input::-webkit-input-placeholder {
   color: var(--silver-color);
   font-family: 'Ubuntu Regular';
}

.ant-input::-moz-placeholder {
   color: var(--silver-color);
   font-family: 'Ubuntu Regular';
}

.ant-input:-ms-input-placeholder {
   color: var(--silver-color);
   font-family: 'Ubuntu Regular';
}

.ant-input::-ms-input-placeholder {
   color: var(--silver-color);
   font-family: 'Ubuntu Regular';
}

.ant-input::placeholder {
   color: var(--silver-color);
   font-family: 'Ubuntu Regular';
}

.ant-layout-header {
   height: 55px;
   padding: 0 25px;
   color: rgba(0, 0, 0, 0.85);
   line-height: 64px;
   background: var(--navyBlue-color);
   display: flex;
   align-items: center;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
   background-color: var(--very-light-blue);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
   border-right: none;
}

.ant-menu-item-selected,
.ant-menu-item-selected a,
.ant-select-selection-item {
   color: var(--primary-color) !important;
}

.ant-select-selection-item {
   font-size: 1rem;
}

.ant-table-wrapper {
   width: 100%;
   margin-top: 0.5rem;
}

/* .ant-table-container {
  min-height: 63vh;
} */

.ant-empty-normal {
   margin: 25px 0;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
   position: relative;
   padding: 14px 12px;
   overflow-wrap: break-word;
}

.ant-table-tbody > tr:hover {
   color: var(--primary-color);
}

.ant-table-tbody > tr:not(:last-child) > td {
   border-bottom: 1px solid var(--light-blue);
}

.ant-table-cell p {
   margin-bottom: 0;
   color: rgba(0, 0, 0, 0.87);
}

.ant-table-tbody > tr:last-child > td {
   border-bottom: none;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td,
.ant-table-tbody > tr.ant-table-row-selected > td {
   background: rgba(84, 77, 160, 0.05);
}

.ant-table {
   top: 15%;
   border-radius: var(--borderRadius5);
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after,
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
   box-shadow: none;
}

.ant-table-thead > tr > th {
   background-color: var(--light-blue);
   border-bottom: 2px solid var(--light-purple);
   font-weight: 600;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
   background-color: var(--primary-color-grey) !important;
   width: 1px;
   height: 1.8em;
}

.ant-table-filter-trigger,
.ant-table-column-sorter {
   color: var(--primary-color-grey);
}

.ant-table-filter-trigger:hover {
   color: var(--primary-color-grey);
   background-color: transparent;
}

.ant-table-filter-dropdown-btns > .ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
   color: var(--primary-color);
   border-color: var(--primary-color);
   background: transparent;
   text-shadow: none;
   box-shadow: none;
   width: 45%;
   font-weight: 400;
   font-size: 13px;
}

.ant-table-filter-dropdown-btns > .ant-btn-primary {
   color: var(--white-color);
   border-color: var(--primary-color);
   background: var(--primary-color);
   text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
   width: 45%;
   font-size: 13px;
}

.ant-table-filter-dropdown .ant-space-item:first-child button.ant-btn-primary,
.ant-table-filter-dropdown .ant-space-item:first-child button.ant-btn-primary:hover,
.ant-table-filter-dropdown .ant-space-item:first-child button.ant-btn-primary:focus {
   color: var(--white-color);
   border-color: var(--primary-color);
   background: var(--primary-color);
}

.ant-table-filter-dropdown .ant-space-item:last-child button.ant-btn-link,
.ant-table-filter-dropdown .ant-space-item:last-child button.ant-btn-link:hover,
.ant-table-filter-dropdown .ant-space-item:last-child button.ant-btn-link:focus {
   color: var(--primary-color);
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected {
   background-color: var(--grey-color);
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active,
.ant-table-filter-trigger.active,
.ant-table-filter-trigger.active > span {
   color: var(--primary-color) !important;
}

.ant-table-thead th.ant-table-column-sort,
.ant-table-thead th.ant-table-column-has-sorters:hover {
   background: var(--light-blue);
}

.ant-table-content {
   border-radius: var(--borderRadius5);
}

.ant-table-filter-dropdown {
   border-radius: var(--borderRadius5);
}

.ant-table-filter-dropdown .ant-btn {
   border-radius: var(--borderRadius5);
}

.ant-table-filter-dropdown .search_btn {
   color: var(--white-color);
   border-color: var(--primary-color);
   background: var(--primary-color);
   text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
   box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
   font-size: 13px;
}

.ant-table-filter-dropdown .ant-btn-default {
   border: 1px solid var(--primary-color);
   color: var(--primary-color);
}

.ant-menu {
   margin-top: 2rem;
   border-right: 0 !important;
   background-color: transparent;
}

.ant-modal-content {
   border-radius: var(--borderRadius5);
}

.ant-modal-centered .ant-modal {
   width: 50% !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
   color: var(--secondary-color-grey);
}

/* scrollbar */
.ant-dropdown-menu {
   border-radius: var(--borderRadius5);
   max-height: 300px;
   max-width: 350px;
   overflow: auto;
   scrollbar-color: var(--primary-color-with-opacity) var(--light-blue);
   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.ant-dropdown-menu::-webkit-scrollbar {
   width: 8px;
}

.ant-dropdown-menu::-webkit-scrollbar-track-piece {
   background-color: var(--light-blue);
}

.ant-dropdown-menu::-webkit-scrollbar-thumb:vertical {
   background-color: var(--primary-color-with-opacity);
}

/* scrollbar */

.ant-tooltip-inner {
   background-color: var(--white-color);
   color: var(--primary-color-grey);
   display: flex;
}

.ant-picker {
   border-radius: var(--borderRadius5);
   border: 1px solid var(--silver-color);
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
}

.ant-picker:hover,
.ant-picker-focused {
   border-color: var(--primary-color);
}

.ant-picker-focused {
   box-shadow: none;
}

.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
   color: var(--primary-color);
   background: var(--light-blue);
   border-color: var(--primary-color);
   cursor: pointer;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
   background: var(--light-blue);
}

.ant-spin-dot-item,
.ant-switch-checked,
.ant-picker-range .ant-picker-active-bar,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
   background: var(--primary-color);
}

.ant-switch:focus,
.ant-switch-checked:focus {
   box-shadow: var(--boxShadowPurple) !important;
}

.ant-radio-checked::after,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
   border: 1px solid var(--primary-color);
}

.ant-radio-checked .ant-radio-inner,
.ant-pagination-item-active,
.ant-pagination-item-active:hover,
.ant-pagination-item:hover {
   border-color: var(--primary-color);
}

.ant-table-pagination.ant-pagination {
   margin-bottom: 0;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
   background-color: var(--third-color-grey) !important;
   color: var(--white-color) !important;
   border: 1px solid var(--third-color-grey) !important;
}

.ant-form-item-extra {
   color: var(--third-color-grey);
}

.ant-btn-link,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-breadcrumb ol li:last-child span a,
.ant-pagination-item-active a,
.ant-pagination-item-active a:hover,
.ant-pagination-item:hover,
.ant-pagination-item:hover a,
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-picker-input input {
   color: var(--primary-color);
}

.ant-picker-input > input {
   font-size: 1rem;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
   border: 1px solid var(--primary-color);
   color: var(--primary-color);
}

.ant-breadcrumb {
   font-family: 'Ubuntu Bold';
   text-transform: capitalize;
}

.ant-breadcrumb-separator,
.ant-breadcrumb ol li:not(:last-child) span a {
   color: var(--black-color);
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
   border-color: var(--primary-color) !important;
}

.ant-select {
   width: 100%;
}

.ant-select-selector {
   height: 40px !important;
   align-items: center;
   border-radius: var(--borderRadius5) !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-radio-input:focus + .ant-radio-inner {
   box-shadow: var(--boxShadowPurple);
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after,
.ant-radio-inner::after {
   background-color: var(--primary-color);
}

.ant-checkbox-checked .ant-checkbox-inner {
   background-color: var(--primary-color);
   border-color: var(--primary-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
   border-color: var(--primary-color) !important;
}

.ant-checkbox-checked::after {
   border: 1px solid var(--primary-color);
}

.ant-popover-buttons {
   text-align: center;
}

.ant-popover-buttons .ant-btn-primary {
   background-color: var(--primary-color);
   border-color: var(--primary-color);
   border-radius: var(--borderRadius5);
}

.ant-popover-buttons .ant-btn {
   border-radius: var(--borderRadius5);
}

.ant-popover-buttons .ant-btn-default:hover,
.ant-popover-buttons .ant-btn-default:focus,
.ant-popover-buttons .ant-btn-default:active {
   border: 1px solid var(--primary-color);
   color: var(--primary-color);
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
   border-radius: var(--borderRadius5);
   height: 40px !important;
   border: 1px solid var(--silver-color);
   display: flex;
   align-items: center;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
   background-color: rgba(84, 77, 160, 0.05);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
   height: 40px;
   text-transform: capitalize;
   color: var(--primary-color);
   font-family: 'Ubuntu Medium' !important;
   font-size: 1rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input[value=''] {
   text-transform: capitalize;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
   border-color: #ff4d4f !important;
}

.ant-dropdown-menu-item:last-child {
   background: var(--white-color);
}

.ant-dropdown-menu-item:last-child:hover {
   background-color: transparent;
}

.view_btn {
   display: flex;
   align-items: center;
}

.view_btn span {
   border-bottom: 1px solid var(--primary-color);
   color: var(--primary-color);
   cursor: pointer;
   border-bottom: 1px solid var(--primary-color);
   white-space: nowrap;
}

.view_btn > img {
   margin-right: 0.25rem;
   width: 1rem;
}

/* message */
.ant-message-notice-content {
   padding: 0;
   width: 50%;
   border-radius: var(--borderRadius5);
   font-family: 'Ubuntu Medium';
   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
}

.ant-message-custom-content {
   padding: 10px 16px;
   min-height: 100px;
   width: 100%;
   border-radius: var(--borderRadius5);
   display: flex;
   align-items: center;
   justify-content: center;
   border-width: 2px;
   border-style: solid;
}

.ant-message-error {
   border-color: var(--alert-error);
   background-color: var(--alert-error-light);
}

.ant-message-success {
   border-color: var(--success-color);
   background-color: var(--alert-light-success);
}

.ant-message-warning {
   border-color: var(--yellow-color);
   background-color: var(--alert-warning-light);
}

.ant-message-info {
   border-color: var(--alert-info);
   background-color: var(--alert-info-light);
}

.ant-message-loading {
   border-color: var(--primary-color);
   background-color: rgba(162, 156, 228, 0.1);
}

/*********************
  END: PUBLIC ANT DESIGN
*********************/

/*********************
  START: MEDIA
*********************/

@media screen and (max-width: 1200px) {
   .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      width: 100%;
   }
}

/*********************
  END: MEDIA
*********************/

/* container */
.responsive__container {
   width: 100%;
   display: grid;
   grid-template-areas: '. content .';
}

.responsive__container > * {
   grid-area: content;
}

/*********************
START: MEDIA
*********************/
@media screen and (min-width: 2560px) {
   .responsive__container {
      grid-template-columns: 1fr 2300px 1fr;
   }
}

@media screen and (min-width: 1920px) and (max-width: 2559px) {
   .responsive__container {
      grid-template-columns: 1fr 1820px 1fr;
   }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
   .responsive__container {
      grid-template-columns: 1fr 1330px 1fr;
   }
}

@media screen and (min-width: 1320px) and (max-width: 1439px) {
   .responsive__container {
      grid-template-columns: 1fr 1310px 1fr;
   }
}

@media screen and (min-width: 1200px) and (max-width: 1319px) {
   .responsive__container {
      grid-template-columns: 1fr 1140px 1fr;
   }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
   .responsive__container {
      grid-template-columns: 1fr 970px 1fr;
   }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
   .responsive__container {
      grid-template-columns: 1fr 750px 1fr;
   }
}

@media screen and (max-width: 768px) {
   .responsive__container {
      grid-template-columns: 1fr 95% 1fr;
   }
   .ant-table-wrapper {
      margin-top: 1rem;
   }
   .ant-modal-centered .ant-modal {
      width: 90% !important;
   }
}

@media screen and (max-width: 768px) {
   .ant-modal-centered .ant-modal {
      width: 100% !important;
   }
}
