 .csvReader_container {
    background-color: #f5f5fa;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80px;
    padding: 10px 12px;
    border: 1.4px dashed var(--primary-color);
    border-radius: var(--borderRadius5);
    width: 100%;
  }
  
  .csvReader_container p{
    margin-bottom: 0;
  }
  
  .uploadFile{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: var(--black-color);
  }
  .upload_btn {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .upload_btn button {
    /* border: none; */
    outline: none;
    cursor: pointer;
    background-color: transparent;
    border-radius: var(--borderRadius5);
    padding: 0.4rem 1rem;
  }
  
  .upload_btn_add{
    color: var(--success-color);
    margin-right: 2rem;
    border: 1px solid var(--success-color);
  }
  
  .upload_btn_remove{
    color: var(--alert-error);
    border: 1px solid var(--alert-error);
  }
  
  .upload_place{
    display: flex;
  }
  
  .upload_place >p{
   margin-bottom: 0;
  }
  
  .upload_place >p{
    color: var(--primary-color-grey);
    text-align: center;
  }
  
  .upload_place >p span{
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
  }
  
  .upload_place :global(.anticon-cloud-upload) {
    color: var(--success-color);
    font-size: 150%;
    -webkit-margin-end: 0.5rem;
    margin-inline-end: 0.5rem;
  }
  
  .progressBarBackgroundColor{
    background-color: var(--light-purple) !important;
    margin-top: 1.5rem;
  }
  