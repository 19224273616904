.businessSelection_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 1.5rem;
    width: fit-content;
}
.businessSelection_container > span{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #C0C0C0;
    margin-bottom: .3rem;
    cursor: auto;
}
.BusinessMenuDropDown{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.currentBusiness_title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-right: 0.5rem;
    color: var(--white-color);
}

.BusinessMenuDropDown :global(.anticon-down){
    margin-top: 3px;
}

.business_item{
    display: flex;
    justify-content: space-between;
}


.createBusiness_btn{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--primary-color-grey) !important;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.createBusiness_btn a{
    width: 100%;
    height: 100%;
}

.createBusiness_btn > img{
    margin-right: 0.5rem;
}