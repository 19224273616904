.page__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.page__header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* position: relative; */
  position: unset;
}

.login_logo{
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page__header > img {
  width: 100%;
}

.page__header > article {
  font-family: 'Ubuntu Bold' !important;
}

.page__vector > img {
  display: block;
  width: 90%;
  -webkit-margin-start: auto;
  margin-inline-start: auto;
}
.header__title {
  margin: 30px 0 15px;
  line-height: 45px;
  font-family: 'Ubuntu Bold';
  font-size: 180%;
}
.header__description {
  margin-bottom: 4rem;
  font-size: 19px;
  line-height: 30px;
  color: var(--secondary-color-grey);
}

/*********************
  START: MEDIA
*********************/
@media screen and (max-width: 768px) {
  .page__header > img {
    width: 65%;
    margin: 0 auto;
  }

  .page__entrance {
    width: 50%;
    margin: 0 auto;
  }

  .page__subText {
    text-align: center;
  }

  .page__header {
    align-items: center;
  }

  .page__header > div {
    text-align: center;
  }
}

/*********************
  END: MEDIA
*********************/
