.steps__container {
   display: flex;
   justify-content: flex-start;
}

.steps_section {
   width: 28%;
   padding: 0 2rem 0 0;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: fixed;
   height: -webkit-fill-available;
   padding-bottom: 1rem;
}

.steps {
   width: 100%;
   margin-top: 3.3rem;
   /* margin-top: -1rem; */
}

.steps :global(.ant-steps-item) {
   width: 100%;
   display: flex;
   align-items: center;
   transition: all 0.2s ease;
}

.steps :global(.ant-steps-item-container) {
   display: flex;
   align-items: center;
   justify-content: flex-start;
   padding: 1rem 1.5rem;
   transition: all 0.2s ease;
   width: 100%;
}

.steps :global(.ant-steps-item-active) {
   background: var(--white-color);
   box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
   border-radius: 5px;
   position: relative;
}

.steps :global(.ant-steps-item-active::after) {
   content: '';
   position: absolute;
   background-image: url(../../../public/static/panel/img/PolygonBoxCampaign.svg);
   background-repeat: no-repeat;
   background-size: contain;
   width: 30px;
   height: 35px;
   top: 27%;
   right: -25px;
}

.steps :global(.ant-steps-item .ant-steps-item-icon .icon_container) {
   width: 45px;
   height: 45px;
   border-radius: 50%;
   background: var(--silver-color-light);
   display: flex;
   align-items: center;
   justify-content: center;
}

.steps :global(.ant-steps-item-active .ant-steps-item-icon .icon_container),
.steps :global(.ant-steps-item-finish .ant-steps-item-icon .icon_container) {
   background: #e7e4f5;
   color: var(--primary-color);
}

.steps :global(.ant-steps-item .ant-steps-item-icon img) {
   width: 25px;
   height: 25px;
}

.steps :global(.ant-steps-item-tail) {
   display: none !important;
}

.steps :global(.ant-steps-item-content) {
   margin-left: 0.5rem;
   min-height: -webkit-fit-content !important;
   min-height: -moz-fit-content !important;
   min-height: fit-content !important;
}

.steps :global(.ant-steps-item-content .ant-steps-item-title) {
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 23px;
   font-family: 'Ubuntu Medium';
   color: var(--black-color) !important;
}

.steps :global(.ant-steps-item-content .ant-steps-item-description) {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
   color: var(--secondary-color-grey) !important;
   padding: 0;
   margin-top: 5px;
}
.steps :global(.ant-steps-item-finish .ant-steps-item-content *) {
   color: var(--primary-color) !important;
}

.tutorial_container {
   width: 100%;
   background: #4b468066;
   border: 1px solid var(--primary-color);
   border-radius: 5px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
   margin-top: 0.5rem;
}

.tutorial_container h3 {
   color: var(--primary-color);
}

.step_content__container {
   width: 100%;
   height: 100%;
   margin-left: 29%;
}

/* .step_content__container :global(.ant-form){
    min-height: 70vh;
} */

.steps_action_buttons {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 0.7rem;
}

.steps_action_buttons :global(.anticon-right),
.steps_action_buttons :global(.anticon-left) {
   display: flex;
   align-items: center;
   font-size: 12px;
   margin-top: 1px;
}

.steps_action_buttons :global(.ant-btn:last-child) {
   padding-left: 1.2rem;
}

.steps_action_buttons :global(.ant-btn:first-child) {
   padding-right: 1.2rem;
}

.step_content {
   padding: 2rem;
   border-radius: var(--borderRadius5);
   width: 100%;
   height: 100%;
   background: var(--white-color);
   /* min-height: 80vh; */
   /* min-height: 70vh; */
   min-height: calc(100vh - 170px);
}

/* .step_contentIsTestCampaign{
    min-height: 70vh;
} */

.testCampaign_note {
   margin-right: 0.8rem;
   margin-left: 0.8rem;
   font-size: 16px;
   text-align: right;
}

@media screen and (min-width: 1440px) {
   .steps_section {
      width: 25%;
   }

   .step_content__container {
      margin-left: 26%;
   }
}

@media screen and (max-width: 1200px) {
   .steps :global(.ant-steps-item-content .ant-steps-item-title) {
      font-size: 16px;
   }

   .steps :global(.ant-steps-item-content .ant-steps-item-description) {
      font-size: 12px;
   }

   .steps :global(.ant-steps-item-container) {
      padding: 0.75rem 1rem;
   }

   .step_content__container {
      margin-left: 31%;
   }

   .steps_section {
      width: 30%;
   }
}

@media screen and (max-width: 992px) {
   .steps__container {
      flex-direction: column;
   }

   .steps_section {
      width: 100%;
      height: fit-content;
      overflow-x: scroll;
      position: relative;
      padding: 10px 0;
   }
   .steps_section::-webkit-scrollbar {
      display: none;
   }
   .step_content {
      min-height: 60vh;
   }
   .step_content__container {
      width: 100%;
      margin: 1rem 0 0 0;
   }

   .steps_section :global(.ant-steps) {
      flex-direction: row;
      margin-top: 1rem;
   }

   .steps :global(.ant-steps-item) {
      width: fit-content;
      display: flex;
      align-items: center;
      transition: all 0.2s ease;
   }
   .steps :global(.ant-steps-item .ant-steps-item-icon) {
      margin: 0;
   }
   .steps :global(.ant-steps-item .ant-steps-item-icon .ant-steps-icon) {
      font-size: 18px;
   }
   .steps :global(.ant-steps-item .ant-steps-item-icon .ant-steps-icon .icon_container) {
      width: 28px;
      height: 28px;
   }
   .steps :global(.ant-steps-item-active::after) {
      display: none;
   }
   .steps :global(.ant-steps-item-container) {
      flex-direction: column;
      padding: 10px;
   }
   .steps :global(.ant-steps-item-container .ant-steps-item-content) {
      margin: 5px 0 0 0;
   }

   .steps :global(.ant-steps-item-container .ant-steps-item-content .ant-steps-item-title) {
      padding: 0;
      font-size: 14px;
   }
   .steps :global(.ant-steps-item-container .ant-steps-item-content .ant-steps-item-description) {
      display: none;
   }
}

@media screen and (max-width: 425px) {
   .steps_section {
      width: 100%;
   }
}
