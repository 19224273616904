@font-face {
  font-family: 'icomoon';
  src:  url('../assets/iconFonts/icomoon.eot?htexhn');
  src:  url('../assets/iconFonts/icomoon.eot?htexhn#iefix') format('embedded-opentype'),
    url('../assets/iconFonts/icomoon.ttf?htexhn') format('truetype'),
    url('../assets/iconFonts/icomoon.woff?htexhn') format('woff'),
    url('../assets/iconFonts/icomoon.svg?htexhn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-agents:before {
  content: "\e900";
}
.icon-businessInformation:before {
  content: "\e901";
}
.icon-customers:before {
  content: "\e902";
}
.icon-home .path1:before {
  content: "\e903";
}
.icon-home .path2:before {
  content: "\e904";
  margin-left: -1em;
  opacity: 0.1;
}
.icon-home .path3:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-home .path4:before {
  content: "\e906";
  margin-left: -1em;
}
.icon-message_formats:before {
  content: "\e907";
}
.icon-platforms:before {
  content: "\e908";
}
.icon-send_campaign:before {
  content: "\e909";
}
.icon-sidebar_agents:before {
  content: "\e90a";
}
.icon-sidebar_businessInformation:before {
  content: "\e90b";
}
.icon-sidebar_campaigns:before {
  content: "\e90c";
}
.icon-sidebar_customers:before {
  content: "\e90d";
}
