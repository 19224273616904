.blue__background {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  border-color: var(--primary-color);
}

.blue__background:hover,
.blue__background:focus {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.red__background{
  background-color: var(--alert-error);
  color: var(--white-color);
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  border-color: var(--alert-error);
}

.red__background:hover, 
.red__background:focus{
  color: var(--alert-error);
  border: 1px solid var(--alert-error);
}

.red__background:global(.ant-btn-loading){
  background-color: var(--alert-error) !important;
}


.green__background{
  background-color: var(--success-color);
  color: var(--white-color);
  padding: 1.2rem 2rem;
  display: flex;
  align-items: center;
  border: 1px solid var(--success-color);
}

.green__background:hover, 
.green__background:focus{
  color: var(--success-color);
  border: 1px solid var(--success-color);
}

.white__background {
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--borderRadius5);
  display: flex;
  align-items: center;
}

.white__background:hover,
.white__background:focus {
  color: var(--white-color);
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.btn {
  border-radius: var(--borderRadius5);
  padding: 1.2rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.large_btn {
  padding: 1.2rem 2rem;
}

.small_btn {
  padding: 10px 12px;
  font-size: 10px;
}

.transparent__background {
  background-color: transparent !important;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.transparent__background:hover, 
.transparent__background:focus, 
.transparent__background:active, 
.transparent__background:disabled, 
.transparent__background:disabled:hover {
  background-color: transparent !important;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.delete_btn {
  border: 1px solid var(--alert-error) !important;
  color: var(--alert-error) !important;
  transition: all 0.5s;
}

.delete_btn>svg {
  -webkit-margin-end: 0.25rem;
  margin-inline-end: 0.25rem;
  transition: all 0.5s;
}

.delete_btn:hover,
.delete_btn:focus {
  background-color: var(--alert-error) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--alert-error) !important;
  transition: all 0.5s;
}

.delete_btn:hover > svg path,
.delete_btn :focus > svg path {
  fill: var(--white-color) !important;
  transition: all 0.5s;
}

.delete_btn:global(.ant-btn-loading){
  background-color: var(--alert-error) !important;
}

.button:global(.ant-btn[disabled]),
.button:global(.ant-btn[disabled]:hover),
.button:global(.ant-btn[disabled]:focus){
  background-color: var(--white-color) !important;
  border: 1px solid var(--alert-error);
  color: var(--alert-error);
}