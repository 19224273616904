.profileMenuDropDown.noPackage {
   margin-left: 1rem;
}
.profileMenuDropDown {
   margin-left: auto;
   color: var(--grey-color);
   cursor: pointer;
}
.access_Limited {
   margin-left: auto;
   display: flex;
   align-items: center;
   justify-content: center;
   font-family: 'Ubuntu Medium';
   border-right: 1px solid var(--third-color-grey);
   padding-right: 1rem;
   height: 40px;
}
.access_Limited p {
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 18px;
   color: #e24c4b;
}
.access_Limited b {
   margin: 0 12px;
   background-color: #ffffff;
   width: 15px;
   height: 2px;
}
.access_Limited a {
   font-style: normal;
   font-weight: 500;
   font-size: 17px;
   line-height: 18px;
   text-decoration-line: underline;
   cursor: pointer;
   color: #ffffff;
}

.menu__container {
   overflow: hidden;
   padding: 0;
}

.menu__container > li:first-child {
   padding: 0;
}

.menu__container > li:not(.menu__container > li:first-child, .menu__container > li:last-child) {
   padding: 0;
   padding: 4px 9px;
}

.menu__container > li:last-child {
   padding: 0;
}

.menu_title__container {
   background-color: var(--primary-color);
   color: var(--grey-color);
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   padding: 8px 9px;
}

.menu_title__container h4 {
   font-size: 14px;
   line-height: 17px;
   color: var(--white-color);
}

.menu_title__container span {
   font-size: 10px;
}

.menu_item {
   font-size: 13px;
   color: var(--primary-color-grey) !important;
}

.logout_btn {
   width: 100%;
   height: 100%;
   border: none;
   outline: none;
   background-color: transparent;
   color: var(--black-color) !important;
   padding: 5px 9px;
   border-top: 1px solid var(--light-blue);
   text-align: left;
   cursor: pointer;
   display: block;
   width: 100%;
}

.profile_container {
   display: flex;
   align-items: center;
}
.profile_img,
.profile_default_img {
   border-radius: 50%;
   width: 47px;
   height: 47px;
   margin-right: 0.5rem;
}
.profile_default_img {
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #efefef;
}

@media screen and (max-width: 768px) {
   .profileMenuDropDown {
      margin-left: 12px;
   }
}
