.business_tooltip :global(.ant-btn) {
    width: 45%;
    margin-top: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-size: 100%;
}

.business_tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    padding: 10px 1rem;
    font-size: 14px;
    font-weight: bold;
    border-radius: var(--borderRadius5);
    color: var(--primary-color-grey);
}