.headerContainer {
   background-color: var(--primary-color);
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   min-height: 60px;
}
.mobile_menu_btn {
   display: none;
   width: 25px;
}
.headerContainer_left {
   display: flex;
   align-items: center;
   width: 100%;
}

.headerContainer_left > * {
   width: auto;
}

.business_container {
   color: var(--white-color);
   cursor: pointer;
   -webkit-margin-start: 1rem;
   margin-inline-start: 1rem;
   display: flex;
   align-items: center;
}

.logout_btn {
   margin-left: auto;
   padding: 0.5rem 1rem;
   color: var(--white-color);
   cursor: pointer;
}

.logout_btn > span {
   margin-right: 0.5rem;
}

@media screen and (max-width: 450px) {
   .headerContainer {
      min-height: 70px;
      padding: 1rem;
   }

   .headerContainer_left > div:first-child img {
      height: 30px !important;
   }

   .business_container > div:first-child img {
      width: 20px;
   }
}

@media screen and (max-width: 1023px) {
   .headerContainer_left > div:first-child img:last-child {
      display: none;
   }
   .mobile_menu_btn {
      display: block;
   }
}

@media screen and (max-width: 768px) {
   .headerContainer {
      flex-direction: column;
      min-height: 80px;
   }
   .business_container_icon {
      display: none;
   }
   .business_container {
      margin-left: auto;
   }
}

/* @media screen and (max-width: 992px) {
.headerContainer_left {
    width: 55%;
}

} */

@media screen and (min-width: 2560px) {
   .headerContainer {
      width: 2560px;
      margin-left: auto;
      margin-right: auto;
   }
}
