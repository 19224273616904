.sidebar_container {
    width: 230px !important;
    max-width: 230px !important;
    min-width: 230px !important;
    /* min-height: 100vh; */
    /* height: 100%;
    position: fixed; */
}

.sidebar_container :global(.ant-layout-sider-children){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar__up{
    padding-top: 2rem;
}

.sidebar_container:global(.ant-layout-sider-collapsed) {
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
}

.sidebar__menu {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0;
    margin-bottom: 0;
    margin-top: 3rem;
}

.sidebar__menu_item {
    width: 100%;
    list-style: none;
    transition: all .3s ease;
}

.sidebar__menu_item a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 13px 18px;
    transition: all 0.3s ease;
    box-sizing: border-box;
    color: var(--secondary-color-grey);
}

.sidebar__menu_item:hover {
    background: var(--grey-color)
}

.sidebar__menu_item a span:last-child {
    margin-left: 0.8rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
}

.link_container {
    border: 1px solid var(--primary-color);
    border-left: none;
    border-right: none;
    padding: 0.6rem 0.2rem;
    border-left: none;
    border-right: none;
    display: flex;
    align-items: center;
    margin: 40px 15px 0 15px;
}

.link_container :global(a) {
    /* font-size: 65%; */
    font-size: 0.95rem;
    line-height: 1.8;
    color: var(--primary-color);
    font-family: "Ubuntu Medium";
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.sidebar__movie{
    padding: 15px;
}
