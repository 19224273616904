.paragraph {
  font-size: 1rem;
  font-family: 'Ubuntu Medium' !important;
}

.modal :global(.ant-modal-body) {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.ant-typography {
  text-align: left;
}

.modalBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.modalBtns button{
  min-width: 100px;
}

.modalBtns button:last-child {
  margin-left: 1rem;
}

.centered_container .ant-typography,
.centered_container .ant-typography {
  text-align: center;
}

.centered_container .modalBtns {
  justify-content: center;
}

.delete_btn {
  background-color: #e12d3a;
  color: var(--white-color) !important;
  border: none !important;
}

.delete_btn:hover,
.delete_btn:focus,
.delete_btn:active {
  color: #e12d3a !important;
  border: 1px solid #e12d3a !important;
}

.modal :global(.ant-btn) {
  padding: 13px 1rem;
  display: flex;
  align-items: center;
  border-radius: var(--borderRadius5);
  justify-content: center;
  text-transform: capitalize;
}

.alert_container {
  background-color: #ffe9d9;
  width: 100%;
  padding: 1.5rem 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border-left: 2px solid #E24C4B;
  border-width: 5px;
  border-radius: var(--borderRadius5);
}

.alert_container :global(img) {
  width: 2.5rem;
  margin-right: 1rem;
}

.alert_text_container {
  text-align: left;
}

.alert_text_container :global(h5.ant-typography) {
  color: #B92B2A;
  margin-bottom: 0.5rem;
}

.alert_text_container :global(span.ant-typography) {
  color: #E24C4B;
}

.textArea_container>img {
  width: 100%;
}

.textArea_container textarea {
  padding: 0.5rem 0.75rem !important;
  margin-bottom: 1.5rem;
}

.textArea_container ul{
  list-style: disc;
  margin-left: 1rem;
  margin-bottom: 2rem;
}

.textArea_container ul li{
  font-size: 100%;
}

.primary_btn {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
  border: 1px solid var(--primary-color);
}

.primary_btn:hover,
.primary_btn:focus,
.primary_btn:active {
  color: var(--primary-color) !important;
  background: var(--white-color);
  border: 1px solid var(--primary-color);
}

.cancel_btn {
  color: var(--primary-color) !important;
  background-color: var(--white-color);
  border: 1px solid var(--primary-color);
}

.cancel_btn:hover, 
.cancel_btn:focus, 
.cancel_btn:active {
  background-color: var(--primary-color);
  color: var(--white-color) !important;
  border: 1px solid var(--primary-color);
}