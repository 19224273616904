/* Fonts */
/* font-weight : 700 */
@font-face {
    font-family: "Ubuntu Bold";
    src: url("../assets/Fonts/Ubuntu/Bold/e0008b580192405f144f2cb595100969.eot");
    /* IE9*/
    src: url("../assets/Fonts/Ubuntu/Bold/e0008b580192405f144f2cb595100969.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../assets/Fonts/Ubuntu/Bold/e0008b580192405f144f2cb595100969.woff2") format("woff2"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Bold/e0008b580192405f144f2cb595100969.woff") format("woff"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Bold/e0008b580192405f144f2cb595100969.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("../assets/Fonts/Ubuntu/Bold/e0008b580192405f144f2cb595100969.svg#Ubuntu Bold") format("svg");
    /* iOS 4.1- */

}

/* font-weight : 500 */
@font-face {
    font-family: "Ubuntu Medium";

    src: url("../assets/Fonts/Ubuntu/Medium/785d8031758d1fac400213600066aee6.eot");
    /* IE9*/
    src: url("../assets/Fonts/Ubuntu/Medium/785d8031758d1fac400213600066aee6.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../assets/Fonts/Ubuntu/Medium/785d8031758d1fac400213600066aee6.woff2") format("woff2"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Medium/785d8031758d1fac400213600066aee6.woff") format("woff"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Medium/785d8031758d1fac400213600066aee6.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("../assets/Fonts/Ubuntu/Medium/785d8031758d1fac400213600066aee6.svg#Ubuntu Medium") format("svg");
    /* iOS 4.1- */
}

/* font-weight : 400 */
@font-face {
    font-family: "Ubuntu Regular";
    src: url("../assets/Fonts/Ubuntu/Regular/1c5965c2b1dcdea439b54c3ac60cee38.eot");
    /* IE9*/
    src: url("../assets/Fonts/Ubuntu/Regular/1c5965c2b1dcdea439b54c3ac60cee38.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../assets/Fonts/Ubuntu/Regular/1c5965c2b1dcdea439b54c3ac60cee38.woff2") format("woff2"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Regular/1c5965c2b1dcdea439b54c3ac60cee38.woff") format("woff"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Regular/1c5965c2b1dcdea439b54c3ac60cee38.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("../assets/Fonts/Ubuntu/Regular/1c5965c2b1dcdea439b54c3ac60cee38.svg#Ubuntu") format("svg");
    /* iOS 4.1- */
}

@font-face {
    font-family: "Ubuntu Light";
    src: url("../assets/Fonts/Ubuntu/Light/8571edb1bb4662f1cdba0b80ea0a1632.eot");
    /* IE9*/
    src: url("../assets/Fonts/Ubuntu/Light/8571edb1bb4662f1cdba0b80ea0a1632.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */
        url("../assets/Fonts/Ubuntu/Light/8571edb1bb4662f1cdba0b80ea0a1632.woff2") format("woff2"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Light/8571edb1bb4662f1cdba0b80ea0a1632.woff") format("woff"),
        /* chrome、firefox */
        url("../assets/Fonts/Ubuntu/Light/8571edb1bb4662f1cdba0b80ea0a1632.ttf") format("truetype"),
        /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
        url("../assets/Fonts/Ubuntu/Light/8571edb1bb4662f1cdba0b80ea0a1632.svg#Ubuntu Light") format("svg");
    /* iOS 4.1- */
}


/* Fonts */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Ubuntu Bold";
}

h1 {
    font-size: calc(26px + (50 - 26) * ((100vw - 300px) / (2560 - 300))) !important;
}

h2 {
    font-size: calc(24px + (40 - 24) * ((100vw - 300px) / (2560 - 300))) !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin-bottom: 0;
}

button,
button:focus,
button>span,
input,
input:focus,
textarea,
textarea:focus,
select,
select:focus,
.ant-select-selection-item {
    font-family: "Ubuntu Medium" !important;
}

label {
    font-family: "Ubuntu Medium" !important;
    font-size: calc(14px + (22 - 14) * ((100vw - 300px) / (2560 - 300))) !important;
    color: var(--secondary-color-grey) !important;
}