/* whiteBtn, purpleBtn, outlineBtn */
.btn{
  display: flex;
  color: var(--primary-color) !important;
  font-family: "Ubuntu Medium";
  align-items: center;
  margin-top: 0.5rem;
  width: fit-content;
}

.btn:hover{
  color: var(--primary-color);
}

.btn > img{
  cursor: pointer;
}

.whiteBtn, .purpleBtn{
  flex-direction: row;
  border-radius: var(--borderRadius25);
  padding-right: 1rem;
  font-size: 1rem;
}

.whiteBtn > img, .purpleBtn > img{
  margin-right: 0.5rem;
  margin-left: -1px;
}

.whiteBtn{
  background-color: var(--white-color);
}

.purpleBtn {
  border: 1px solid var(--primary-color);
  background-color: transparent;
}

.outlineBtn{
  flex-direction: column;
}

.outlineBtn > img{
  margin-bottom: 0.5rem;
}