.breadcrumb__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid #dcdcdc;
  position: relative;
  padding-bottom: 0.5rem;
  
}

.breadcrumb__container ol li{
  font-size: 21px;
  line-height: 23px;
}

.remainingTitle{
  font-size: 14px;
}