/* .layoutContainer{
  min-height: 100vh;
  overflow-y: hidden;
} */

.content_container {
   padding: 1.5rem;
   position: relative;
   overflow-y: auto !important;
   /* margin-left: 230px; */
}

.mobileMenuContainer {
   visibility: hidden;
   opacity: 0;
   position: fixed;
   inset: 0;
   width: 100%;
   height: 100%;
   z-index: 999;
   background: rgba(33, 25, 36, 0.939);
   transition: all 0.3s ease;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   gap: 12px;
}
.mobileMenuContainer a {
   font-size: 22px;
   padding: 6px;
}

.mobileMenuContainer div {
   margin-top: 40px;
}
.mobileMenuContainer div a {
   font-size: 24px;
   font-weight: bold;
   color: #fff;
   border: 1px solid #fff;
   padding: 10px 12px;
   border-radius: 8px;
}
.mobileMenuContainer button {
   position: absolute;
   top: 25px;
   right: 25px;
   outline: none;
   border: none;
   background: transparent;
   cursor: pointer;
}
.mobileMenuContainer button img {
   width: 35px;
   height: 35px;
}
.mobileMenuContainer.isOpen {
   visibility: visible;
   opacity: 1;
}
.business_information_container {
   display: flex;
   height: 4rem;
   position: relative;
   padding: 0px 1.5rem;
   bottom: 10px;
   font-size: 12px;
   color: rgba(255, 255, 255, 0.712);
}

.business_information_container :global(.ant-space-item) {
   height: 1.1rem;
}

.business_information_container :global(.ant-dropdown-menu-title-content) {
   padding: 1rem;
}

.business_menu {
   background: var(--white-color);
   font-size: 14px;
   flex-direction: column;
   display: flex;
   justify-content: space-between;
   min-height: 100px;
   border-bottom-left-radius: var(--borderRadius5);
   border-bottom-right-radius: var(--borderRadius5);
   padding-top: 1rem;
   -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
   z-index: 999;
   position: absolute;
   transition: height 2s ease-in;
   top: 70px;
   min-width: 300px;
   max-width: 35%;
   color: var(--black-color);
}

.business_menu :global(.ant-row > div) {
   display: flex;
   padding: 0px 1rem;
   line-height: 40px;
   justify-content: space-between;
   width: 100%;
}

.business_menu_link {
   color: var(--black-color);
   font-family: 'Ubuntu Medium';
}

.business_menu_link > p {
   line-height: 2;
   margin-bottom: 0;
}

.business_menu_link:hover,
.business_menu_link:focus {
   color: var(--primary-color);
}

.menu_btn {
   border: none;
   display: flex;
   align-items: center;
   padding-bottom: 10px;
   padding-top: 10px;
   box-shadow: none;
   color: var(--silver-color);
   border-top: 1px solid var(--silver-color);
   height: 45px;
}

.menu_btn:hover,
.menu_btn:focus {
   color: var(--silver-color);
   border-top: 1px solid var(--silver-color);
}

.menu_btn > img {
   -webkit-margin-end: 0.5rem;
   margin-inline-end: 0.5rem;
}

:global(.ant-layout-sider-zero-width-trigger) {
   top: -1px;
   background: var(--primary-color) !important;
   color: var(--white-color) !important;
   border-radius: 0 0 var(--borderRadius5) 0;
}

:global(.ant-menu-title-content) {
   font-family: 'Ubuntu Bold';
}

:global(.ant-menu-title-content > a) {
   color: var(--primary-color-grey);
}

:global(.ant-menu-title-content > a:hover) {
   color: var(--light-purple);
}

/* tooltip */
:global(.ant-tooltip-inner),
:global(.ant-tooltip-arrow-content::before) {
   background: var(--white-color) !important;
}

:global(.ant-tooltip-arrow-content) {
   border: 1px solid var(--primary-color);
   width: 20px;
   height: 20px;
   background-color: var(--white-color);
}

:global(.ant-tooltip) {
   width: 300px !important;
   z-index: 10000;
   /* left: 15px !important; */
}

:global(.ant-tooltip-content) {
   width: 350px;
   left: 15px;
}

:global(.ant-tooltip-content .ant-tooltip-inner) {
   border: 1px solid var(--primary-color) !important;
   border-radius: var(--borderRadius5) !important;
   background: var(--white-color) !important;
   color: var(--primary-color-grey) !important;
   text-align: center;
   margin-top: -4px;
}

:global(.ant-tooltip-placement-bottom .ant-tooltip-arrow),
:global(.ant-tooltip-placement-bottomRight .ant-tooltip-arrow) {
   top: 3px !important;
}

:global(.ant-tooltip-placement-left .ant-tooltip-arrow) {
   bottom: 3px !important;
   right: 3px !important;
}

/* tooltip */
/*********************
  START: MEDIA
*********************/
@media screen and (min-width: 2560px) {
   :global(.ant-layout.ant-layout-has-sider) {
      width: 2560px;
      margin-left: auto;
      margin-right: auto;
   }
}

@media screen and (max-width: 1200px) {
   /* :global(.ant-layout-sider) {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    height: 100%;
    border-right: 3px solid var(--primary-color);
    display: block;
  }

  :global(.ant-layout-sider.ant-layout-sider-collapsed) {
    border-right: none;
  } */

   .business_menu {
      max-width: 40%;
   }
}

@media screen and (max-width: 992px) {
   .business_menu {
      max-width: 50%;
   }
}

@media screen and (max-width: 768px) {
   .business_menu {
      top: 90px;
      left: 20px;
      max-width: 60%;
   }
}

@media screen and (max-width: 576px) {
   .business_menu {
      max-width: 70%;
   }
   .content_container {
      padding: 16px;
   }
}

@media screen and (max-width: 425px) {
   .content_container {
      padding: 1rem;
   }

   .business_menu {
      max-width: 90%;
   }
}

/*********************
  END: MEDIA
*********************/
